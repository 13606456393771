* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
html {
  font-family: DefaultSans;
  line-height: 1.42857143;
  font-size: 16px;
}
body {
  overflow-y: scroll;
}

#__next {
  display: table;
  width: 100%;
  height: 100%;
}
#__next > header,
#__next > footer {
  display: table-row;
  height: 0%;
}
#__next > main {
  display: table-row;
  height: 100%;
}
