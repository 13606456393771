@import '../../static/fontawesome/css/fontawesome.css';

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('/static/fontawesome/webfonts/fa-regular-400.woff2') format('woff2'),
    url('/static/fontawesome/webfonts/fa-regular-400.woff') format('woff'),
    url('/static/fontawesome/webfonts/fa-regular-400.ttf') format('truetype');
}

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url('/static/fontawesome/webfonts/fa-solid-900.woff2') format('woff2'),
    url('/static/fontawesome/webfonts/fa-solid-900.woff') format('woff'),
    url('/static/fontawesome/webfonts/fa-solid-900.ttf');
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('/static/fontawesome/webfonts/fa-brands-400.woff2') format('woff2'),
    url('/static/fontawesome/webfonts/fa-brands-400.woff') format('woff'),
    url('/static/fontawesome/webfonts/fa-brands-400.ttf');
}
.fab {
  font-family: 'Font Awesome 5 Brands';
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  margin: 0 0 0.7em 0;
  font-weight: 400;
}
p {
  margin: 0 0 0.7em 0;
  font-weight: 400;
}

h1,
.h1 {
  font-size: 3rem;
}
h2,
.h2 {
  font-size: 2.5rem;
}
h3,
.h3 {
  font-size: 2rem;
}
h4,
.h4 {
  font-size: 1.5rem;
}
h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 1rem;
}

small {
  line-height: inherit;
  font-size: 85%;
}

p,
.p,
span,
.span {
  line-height: inherit;
  font-size: inherit;
}
