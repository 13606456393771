@import 'normalize.css';
@import 'page_layout.css';
@import 'typography.css';

ul {
  margin: 0 0 1em;
  line-height: 1.42857143;
}

a {
  color: inherit;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
button {
  cursor: pointer;
}

.grecaptcha-badge {
  visibility: hidden;
}

.react-joyride__tooltip button {
  outline: none;
}

.react-joyride__overlay {
  position: fixed !important;
}

.__floater {
  transition: unset !important;
}

body > .skiptranslate {
  visibility: hidden !important;
  opacity: 0 !important;
}

body > .skiptranslate ~ .skiptranslate {
  visibility: visible !important;
  opacity: 1 !important;
}

body {
  top: 0px !important;
}
